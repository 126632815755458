@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

html {
  background: #0F172A
}

html, body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'MonomaniacOne-Regular';
  src: url("./fonts/MonomaniacOne-Regular.ttf") format('truetype');
}

* {
  font-family: "Roboto Condensed", sans-serif;
  box-sizing: border-box
}

.App {
  height: 100vh;
  max-height: -webkit-fill-available;
}